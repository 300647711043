.pink {
  color: pink;
}

.imgZoom {
  animation-name: zoom-in;
  animation-duration: 20s;
  animation-timing-function: linear;
}

.imgZoomDefault {
  transform: scale(1, 1);
}

@keyframes zoom-in {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.2, 1.2);
  }
}
