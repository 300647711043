@use "../../../theme.scss" as *;

// Animations used in Background Music Component
//dancing animation for dancing playing icon
@keyframes MoveUpDown1 {
  0%,
  100% {
    height: 20px;
  }
  50% {
    height: 5px;
  }
}
@keyframes MoveUpDown2 {
  0%,
  100% {
    height: 4px;
  }
  50% {
    height: 20px;
  }
}
@keyframes MoveUpDown3 {
  0%,
  100% {
    height: 10px;
  }
  50% {
    height: 20px;
  }
}
@keyframes MoveUpDown4 {
  0%,
  100% {
    height: 20px;
  }
  50% {
    height: 5px;
  }
}

//All styles related to Background Music Component
$songRowDeviderColor: #f2f2f2;
$songUploadRowHeight: 66px;
$borderNone: none !important;
.mainView {
  margin-bottom: 56px;
}
.mainRow {
  border-top: 1px solid $songRowDeviderColor;
  padding-left: 4px;
}

.borderBottom {
  border-bottom: 1px solid $songRowDeviderColor;
}

.rowHeight {
  height: $songUploadRowHeight;
}

.videoRectangleBarContainer {
  position: absolute;
  top: 50%;
  height: 50%;
  background: white;
  border-bottom: 1px solid $songRowDeviderColor;
  z-index: 5;
}

.videoRectangleBar {
  color: #fff;
  height: 14px;
  font-size: 10px;
  padding: 1px 1px 1px 8px;
  border-radius: 1px 1px 3px 3px;
}

.songCount {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.29);
  height: 15px;
  width: 15px;
}

.icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.playPauseButton {
  height: 23px;
  width: 23px;
  cursor: pointer;
}

.songTitle {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.49);
  margin-bottom: 2px;
}

.songArtist {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.29);
}

.songDuration {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.49);
}

.uploadSongButton {
  margin-left: 34px;
  margin-right: 14px;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  width: 135px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mainWaveFormView {
  height: $songUploadRowHeight;
  width: 100%;
}

//following styles when these are written in index.scss, conflicting with bootstrap classes
// so If someone wants to add background music styles to index.scss so keep the following styles in this file,
// so that it gets more priority to overcome style conflicts
.songUploadRow {
  height: 51px;
  align-items: center;
}
.input {
  height: 36px;
  &::placeholder {
    color: #757575;
    font-size: 12px;
  }
}
.uploadButton {
  background-color: $primaryMain;
  color: #fff;
  width: 100px;
  font-weight: 700;
  border-radius: 4px;
  height: 30px;
  border: $borderNone;
}

.songItemPreviewButton,
.songItemPreviewButton:hover {
  border-color: $primaryMain;
  border-radius: 5px;
  height: 25px;
  color: $primaryMain;
  font-size: 0.83vw;
  font-weight: bolder;
}
.songDetail {
  padding-left: 45px;
  padding-right: 51px;
}
.songsPlatformTitle {
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 500;
  height: 46px;
  margin-bottom: 5px;
  margin-top: 48px;
}
.dancingPlayIcon {
  height: 20px;
  width: 24px;
}
.dancingBar {
  background: rgba$primaryMain;
  border-radius: 3px 3px 0 0;
  margin-right: 2px;
  width: 4px;
  height: 18px;
}
.npPadding {
  padding: 0;
}
.withPadding {
  padding: 2px 0 0 30px;
}
.soundCloudModal {
  //change the modal styles here
  width: 650px !important;
  max-width: none !important;
  .modal-content {
    height: 270px !important;
    border: $borderNone;
    border-radius: 10px !important;
  }
  .soundCloudHeading {
    font-size: 22px;
    font-weight: 700;
    color: $primaryMain;
    margin-bottom: 26px;
  }
  .soundCloudSongName {
    font-size: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
  .soundCloudUploadButton {
    background-color: $primaryMain;
    color: #fff;
    width: 119px;
    font-weight: 500;
    border-radius: 5px;
    border: $borderNone;
  }
  .soundCloudSongUploadRow {
    margin-bottom: 48px;
    margin-top: 28px;
  }
}
.soundCloudLinksButton {
  margin-right: 1.7em;
}
.progressView {
  height: 51px;
}
.uploadProgress {
  height: 25px;
}
.progressBar {
  background-color: $primaryMain;
  text-align: center;
  font-size: 1em;
}
.successModal {
  width: 650px !important;
  max-width: none !important;
  height: 270px !important;
  border-radius: 10px;
  border: $borderNone;
  object-fit: contain;
}
