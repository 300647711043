@use "theme" as *;

$primary: $primaryMain;
@import "../node_modules/bootstrap/scss/bootstrap";

//SOLVING SCROLLING BUG: when "scroll-behavior: smooth;" is set like in the original bootstrap template scrolling isn't working correctly together with DnD
//Hence this needs to be overwritten with "scroll-behavior: auto;"
:root {
  @if $enable-smooth-scroll {
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: auto;
    }
  }
}
