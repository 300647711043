/* ONLY NEEDED FOR WINDOWS */
/* Hide scrollbar for Chrome, Safari and Opera */
.textareaScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.textareaScrollBar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
