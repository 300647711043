@use "theme" as *;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.App {
  font-family: "Roboto";
  text-align: center;
  margin: 0;
  padding: 0;
  background: "blue";
}

html {
  background-color: white;
}

$primary: $primaryMain;

input:focus,
input.form-control:focus {
  outline: none;
}

//These two classes are used for the textfields in the components/segments/Affirmation component
//with them the placeholders of the textfield are set white or pink according if there has video/image
//been schoosen as a background or not
.placeholderWhite::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.placeholderPrimary60::placeholder {
  color: $primaryOpcaity60;
}

textarea:focus,
textarea.form-control:focus {
  outline: none;
}

@import "../node_modules/bootstrap/scss/bootstrap";
